
import { Vue, Component, Prop } from 'vue-property-decorator'

import MainWithSidePanelLayout from '@/layouts/MainWithSidePanelLayout.vue'
import MainWithSidePanelLayoutNavigation from '@/components/MainWithSidePanelLayoutNavigation.vue'
import ProfileProfile from '@/components/ProfileProfile.vue'
import ProfileBilling from '@/components/ProfileBilling.vue'
import ProfilePassword from '@/components/ProfilePassword.vue'
import ProfileNotifications from '@/components/ProfileNotifications.vue'
import { NavigationLink } from '@/models/NavigationLink'
import profile from '@/store/modules/profile'

@Component({
  components: {
    MainWithSidePanelLayout,
    MainWithSidePanelLayoutNavigation,
    ProfileProfile,
    ProfilePassword,
    ProfileNotifications,
    ProfileBilling,
  },
})
export default class Profile extends Vue {
  profile = profile

  links: NavigationLink[] = [
    {
      name: 'profile',
      value: ProfileProfile,
      icon: 'mdi-account',
      label: this.$t('profile.PROFILE.TITLE'),
    },
    {
      name: 'billing',
      value: ProfileBilling,
      icon: 'mdi-credit-card',
      label: this.$t('profile.BILLING.TITLE'),
    },
    {
      name: 'password',
      value: ProfilePassword,
      icon: 'mdi-lock',
      label: this.$t('profile.PASSWORD.TITLE'),
    },
    {
      name: 'notifications',
      value: ProfileNotifications,
      icon: 'mdi-bell',
      label: this.$t('profile.NOTIFICATIONS.TITLE'),
    },
  ]

  get component() {
    return (
      this.links.find((component) => this.$route.name === component.name)?.value ||
      null
    )
  }

  mounted(): void {
    profile.loadCustomerProfile()
  }
}
